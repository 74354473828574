<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        A
        <number-value :value="volA" unit="\text{mL}" />
        solution of
        <stemble-latex content="$\text{0.600 M compound A}$" />
        is mixed with an excess of
        <stemble-latex content="$\text{compound B}$" />
        solution at
        <number-value :value="Ti" unit="^{\circ}\text{C}" />
        in a styrofoam calorimeter (assume the heat capacity of the calorimeter is zero). The
        chemicals react to form
        <stemble-latex content="$\text{compound C}$" />
        and water, and the temperature rises to
        <number-value :value="Tf" unit="^{\circ}\text{C.}" />
        If the mass of the total reaction solution is
        <number-value :value="solutionMass" unit="\text{g,}" />
        calculate the molar enthalpy,
        <stemble-latex content="$\Delta\text{H,}$" />
        of the reaction (in J/mol). The specific heat capacity of the reaction solution is
        <stemble-latex content="$\text{4.184 J g}^{-1}\,^{\circ}\text{C}^{-1}\text{.}$" />
      </p>

      <calculation-input
        v-model="inputs.enthalpy"
        prepend-text="$\Delta\text{H:}$"
        append-text="$\text{J/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question257',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        enthalpy: null,
      },
    };
  },
  computed: {
    volA() {
      return this.taskState.getValueBySymbol('volA').content;
    },
    Ti() {
      return this.taskState.getValueBySymbol('Ti').content;
    },
    Tf() {
      return this.taskState.getValueBySymbol('Tf').content;
    },
    solutionMass() {
      return this.taskState.getValueBySymbol('solutionMass').content;
    },
  },
};
</script>
